import CreateNewTrial from "../Components/CreateNewTrial";
import NewTrial from "../Components/NewTrial";
import Loader from "../../BottomPanel/Loader"

function TrialManagement({ trialData, setTrialData, saveTrial, isLoading }) {

  if (isLoading) {
    return (
      <div className="h-full w-full flex items-center justify-center">
        <Loader />
      </div>
    );
  }
  return (
    <div className="grow flex flex-col">
      <div className="px-5 py-3 border-b border-b-gray-700">
        <h3 className="leading-[26px]">Trial Management</h3>
      </div>

      <div className="grow flex flex-col">
        <NewTrial
          trialData={trialData}
          setTrialData={setTrialData}
          saveTrial={saveTrial}
        />
        {/*<div>
          <CreateNewTrial />
        </div>*/}
      </div>
    </div>
  );
}

export default TrialManagement;
