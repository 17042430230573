import { useState } from "react";
import classNames from "classnames";
import AccordionContent from "./AccordionContent";
import { get } from "lodash";

function Characteristic({ trialData, setTrialData }) {
  const [activeAccordion, setActiveAccordion] = useState();

  const organs = get(trialData, ["characterstics", "organs"], {});

  return (
    <div className="grow h-[1px] flex flex-col overflow-y-scroll">
      {Object.keys(organs).map((organ, index) => {
        const isOpen = organ === activeAccordion;
        const characteristics = organs[organ];
        return (
          <div
            key={organ}
            className={classNames(
              "flex flex-col border-b text-[12px] border-gray-700 transition-all",
              {
                grow: isOpen,
              }
            )}
          >
            <button
              className="p-5 flex items-center justify-between"
              onClick={() => {
                activeAccordion === organ
                  ? setActiveAccordion()
                  : setActiveAccordion(organ);
              }}
            >
              <span className="uppercase font-semibold">{organ}</span>
              <span
                className={classNames("transition-all duration-300", {
                  "rotate-180": organ === activeAccordion,
                })}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 10L12 14L16 10"
                    stroke="#BBBFC2"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </button>

            <div
              className={classNames(
                "grow h-0 bg-gray-900 overflow-y-scroll transition-all",
                {
                  "h-[250px]": isOpen,
                }
              )}
            >
              <AccordionContent
                organ={organ}
                characteristics={characteristics}
                trialData={trialData}
                setTrialData={setTrialData}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Characteristic;
