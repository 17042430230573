import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";

const Toast = () => {
  return (
    <ToastContainer
      position="bottom-center"
      autoClose={750}
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="colored"
    />
  );
};

Toast.success = (msg) => {
  return toast.success(msg, {
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 1.875C5.51172 1.875 1.875 5.51172 1.875 10C1.875 14.4883 5.51172 18.125 10 18.125C14.4883 18.125 18.125 14.4883 18.125 10C18.125 5.51172 14.4883 1.875 10 1.875ZM7.97656 13.2734C8.40234 13.6992 9.09766 13.6992 9.52344 13.2734L14.5234 8.27344C14.9492 7.84766 14.9492 7.15234 14.5234 6.72656C14.0977 6.30078 13.4023 6.30078 12.9766 6.72656L8.75 10.9531L7.02344 9.22656C6.59766 8.80078 5.90234 8.80078 5.47656 9.22656C5.05078 9.65234 5.05078 10.3477 5.47656 10.7734L7.97656 13.2734Z"
          fill="#00C86E"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10 20C15.5234 20 20 15.5234 20 10C20 4.47656 15.5234 0 10 0C4.47656 0 0 4.47656 0 10C0 15.5234 4.47656 20 10 20ZM1.875 10C1.875 5.51172 5.51172 1.875 10 1.875C14.4883 1.875 18.125 5.51172 18.125 10C18.125 14.4883 14.4883 18.125 10 18.125C5.51172 18.125 1.875 14.4883 1.875 10Z"
          fill="#00C86E"
        />
      </svg>
    ),
  });
};

Toast.warning = (msg) => {
  return toast.warning(msg, {
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0004 18.1021L1.90039 10.0045L9.95508 1.87559H9.96637C9.98262 1.87559 9.99757 1.87932 10.0077 1.88306C10.0126 1.88436 10.0151 1.88558 10.0177 1.8868L18.0997 10.004L10.0004 18.1021ZM8.75039 13.7154C8.75039 13.0256 9.31055 12.4654 10.0004 12.4654C10.6918 12.4654 11.2504 13.0256 11.2504 13.7154C11.2504 14.4053 10.6902 14.9654 10.0004 14.9654C9.31055 14.9654 8.75039 14.4053 8.75039 13.7154ZM9.06289 10.2779V5.90293C9.06289 5.41855 9.48281 4.96543 10.0004 4.96543C10.518 4.96543 10.9379 5.38535 10.9379 5.90293V10.2779C10.9379 10.7955 10.518 11.2154 10.0004 11.2154C9.48281 11.2154 9.06289 10.7955 9.06289 10.2779Z"
          fill="#FFA000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4418 8.6959L11.309 0.52793C10.5664 -0.175977 9.3539 -0.175977 8.65039 0.52793L0.557812 8.6959C-0.185938 9.39824 -0.185938 10.6111 0.557812 11.3135L8.69062 19.4424C9.39453 20.1861 10.6066 20.1861 11.3105 19.4424L19.4434 11.3135C20.1879 10.61 20.1879 9.39902 19.4418 8.6959ZM10.0004 18.1021L1.90039 10.0045L9.95508 1.87559H9.96637C9.98262 1.87559 9.99757 1.87932 10.0077 1.88306C10.0126 1.88436 10.0151 1.88558 10.0177 1.8868L18.0997 10.004L10.0004 18.1021Z"
          fill="#FFA000"
        />
      </svg>
    ),
  });
};

Toast.error = (msg) => {
  return toast.error(msg, {
    icon: (
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0004 18.1021L1.90039 10.0045L9.95508 1.87559H9.96637C9.98262 1.87559 9.99757 1.87932 10.0077 1.88306C10.0126 1.88436 10.0151 1.88558 10.0177 1.8868L18.0997 10.004L10.0004 18.1021ZM8.75039 13.7154C8.75039 13.0256 9.31055 12.4654 10.0004 12.4654C10.6918 12.4654 11.2504 13.0256 11.2504 13.7154C11.2504 14.4053 10.6902 14.9654 10.0004 14.9654C9.31055 14.9654 8.75039 14.4053 8.75039 13.7154ZM9.06289 10.2779V5.90293C9.06289 5.41855 9.48281 4.96543 10.0004 4.96543C10.518 4.96543 10.9379 5.38535 10.9379 5.90293V10.2779C10.9379 10.7955 10.518 11.2154 10.0004 11.2154C9.48281 11.2154 9.06289 10.7955 9.06289 10.2779Z"
          fill="red"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.4418 8.6959L11.309 0.52793C10.5664 -0.175977 9.3539 -0.175977 8.65039 0.52793L0.557812 8.6959C-0.185938 9.39824 -0.185938 10.6111 0.557812 11.3135L8.69062 19.4424C9.39453 20.1861 10.6066 20.1861 11.3105 19.4424L19.4434 11.3135C20.1879 10.61 20.1879 9.39902 19.4418 8.6959ZM10.0004 18.1021L1.90039 10.0045L9.95508 1.87559H9.96637C9.98262 1.87559 9.99757 1.87932 10.0077 1.88306C10.0126 1.88436 10.0151 1.88558 10.0177 1.8868L18.0997 10.004L10.0004 18.1021Z"
          fill="red"
        />
      </svg>
    ),
  });
};

export default Toast;
