import { useState } from "react";
import { CheckboxSVG } from "../../../../utils";

function CharacteristicTypes({ characteristicTypes, organ, characteristic, selectedType, onTypeChangeHandler }) {

  return (
    <div className="flex items-center w-full justify-between">
      {characteristicTypes.map((characteristicType) => {
        const { id, name: label } = characteristicType;
        return (
          <label key={id} className="select-none cursor-pointer flex items-center space-x-3">
            <input
              className="hidden"
              type="radio"
              name={organ + characteristic}
              value={id}
              checked={id === selectedType}
              onChange={(e) => {
                onTypeChangeHandler(organ, characteristic, e.target.value)
              }}
            />
            <CheckboxSVG isChecked={id === selectedType}/>
            <span>{label}</span>
          </label>
        );
      })}
    </div>
  );
}

export default CharacteristicTypes;
