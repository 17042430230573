import classNames from "classnames";
import {
  TRIAL_MANAGEMENT,
  USER_MANAGEMENT,
  USER_SETTINGS_MENU,
  OTHERS_MENU,
} from "./utils";

function Sidebar({ activeMenu, updateActiveMenu }) {
  return (
    <div className="w-1/3 bg-gray-900">
      <div className="px-5 py-3 border-b border-b-gray-800">
        <h3 className="text-lg">Settings</h3>
      </div>

      <div className="text-gray-400">
        <div className="pt-3">
          <h4 className="uppercase text-[12px] font-semibold px-5 py-3 border-b border-gray-800">User Settings</h4>

          <div className="">
            {USER_SETTINGS_MENU.map((userSetting) => {
              const { id, label, icon: Icon } = userSetting;
              return (
                <button
                  key={id}
                  className={classNames(
                    "w-full flex items-center space-x-3 px-5 py-3 border-b border-gray-800",
                    {
                      "bg-gray-800": id === activeMenu,
                    }
                  )}
                  onClick={() => {
                    updateActiveMenu(id);
                  }}
                >
                  <div>
                    <Icon />
                  </div>
                  <div>{label}</div>
                </button>
              );
            })}
          </div>
        </div>

        <div className="pt-3">
          <h4 className="uppercase text-[12px] font-semibold px-5 py-3 border-b border-gray-800">Other</h4>

          <div>
            {OTHERS_MENU.map((otherMenu) => {
              const { id, label, icon: Icon } = otherMenu;
              return (
                <button
                  key={id}
                  className={classNames(
                    "w-full flex items-center space-x-3 px-5 py-3 border-b border-gray-800",
                    {
                      "bg-gray-800": id === activeMenu,
                    }
                  )}
                  onClick={() => {
                    updateActiveMenu(id);
                  }}
                >
                  <div>
                    <Icon />
                  </div>
                  <div>{label}</div>
                </button>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
