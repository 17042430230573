import { useEffect } from "react";
import Toast from "../../Toast";

function UserManagement() {

  useEffect(() => {
    Toast.success("To Be Released in Future Updates")
  }, [])

  return (
    <div className="">
      <div className="px-5 py-3 border-b border-b-gray-700">
        <h3 className="leading-[26px]">UserMangement</h3>
      </div>
    </div>
  );
}
export default UserManagement;
