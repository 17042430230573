var PAGE_SIZE = 10;
var GENDERS = [{
  id: 0,
  label: "Male",
  shortLabel: "M",
  value: 0
}, {
  id: 1,
  label: "Female",
  shortLabel: "F",
  value: 1
}, {
  id: 2,
  label: "Other",
  shortLabel: "O",
  value: 2
}
// { id: 3, label: "NA", shortLabel: "", value: 3 },
];

var AI_INFERENCE = {
  WAITING_FOR_SCAN: 0,
  PROCESSING: 1,
  NEGATIVE: 2,
  POSITIVE: 3,
  URGENT: 4,
  REJECTED: -1,
  FAILED: -2,
  NA: -3
};
var STATUS_OPTIONS = [
// { label: 'Waiting for scan', value: AI_INFERENCE.WAITING_FOR_SCAN },
{
  label: "PROCESSING",
  value: AI_INFERENCE.PROCESSING
}, {
  label: "Negative",
  value: AI_INFERENCE.NEGATIVE
}, {
  label: "Positive",
  value: AI_INFERENCE.POSITIVE
}, {
  label: "Critical",
  value: AI_INFERENCE.URGENT
}, {
  label: "Invalid Scan",
  value: AI_INFERENCE.REJECTED
}, {
  label: "Processing Error",
  value: AI_INFERENCE.FAILED
}, {
  label: "Not Available",
  value: AI_INFERENCE.NA
}];
var RN_COMM_CONST = {
  APP_READY: "APP_READY",
  FLUSH_COOKIES: "FLUSH_COOKIES",
  LOGIN: "LOGIN",
  SHARE: "SHARE",
  ENV_SELECTION: "ENV_SELECTION",
  REGION_URL: "REGION_URL",
  OPEN_APP_SETTINGS: "OPEN_APP_SETTINGS"
};
var SCREENS = {
  WORKLIST: "patients",
  VIEWER: "viewer"
};
var MEDIA_TYPES = {
  IMAGE: "IMAGE",
  DOCUMENT: "DOCUMENT",
  STUDY: "STUDY",
  REPORT: "REPORT",
  FORM: "FORM",
  AUDIO: "AUDIO"
};
var IMAGE_TYPES = ["jpg", "jpeg", "png", "webp"];
var DOC_TYPES = ["pdf", "msword", "doc", "docx", "xls", "xlsx"];
var AUDIO_TYPES = ["mp3", "mpga", "weba", "webm"];
var PLATFORMS = {
  ANDROID: 0,
  PWA: 1,
  IOS: 2
};
var FORMS = {
  STROKE_CLINICAL_INFO: "stroke_clinical",
  STROKE_NIHSS: "nihss",
  TB_SYMPTOMS: "symptoms",
  TB_LABTESTS: "tb_lab_test",
  TB_SPUTUM: "tb-sputum",
  TB_GENEXPERT: "tb-genexpert"
};
var NOTIFICATION_EVENTS = {
  NEW_PATIENT: 0,
  POSITIVE_FINDINGS: 2,
  NEGATIVE_FINDINGS: 3,
  REPORT_UPDATE: 4,
  STROKE_INITIATED: 5,
  PATIENT_SHARED: 7
};
var FORM_FIELDS = {
  DUAL_NUMBER: "dual_number",
  NUMBER: "number",
  MULTISELECT: "multiselect",
  CHECKBOX: "check_box",
  SINGLESELECT: "singleselect",
  DATETIME: "datetime",
  LKWT: "lkwt"
};
var DEEFAULT_FILTER = {
  isFilterApplied: false,
  data: null,
  label: ""
};
var DICOM_METADATA_TAGS = {
  SOPInstanceUID: "x00080018",
  SOPClassUID: "x00080016",
  StudyInstanceUID: "x0020000d",
  SeriesInstanceUID: "x0020000e",
  Modality: "x00080060",
  PatientID: "x00100020",
  AccessionNumber: "x00080050",
  PatientName: "x00100010",
  PatientGender: "x00100040",
  PatientAge: "x00101010",
  StudyDescription: "x00081030",
  SeriesDescription: "x0008103e",
  StudyDate: "x00080020"
};
var REQUIRED_DICOM_METADATA = ["SOPInstanceUID", "SOPClassUID", "SeriesInstanceUID", "StudyInstanceUID", "Modality", "PatientID"];
var REGION_LABELS = {
  global: "Global/Others",
  us: "United States",
  sg: "Singapore",
  uk: "United Kingdom",
  eu: "Europe",
  is: "India Stroke",
  beta2: "Beta 2"
};
var REGIONS = {
  us: "us-app.qure.ai",
  eu: "eu-app.qure.ai",
  sg: "sg-app.qure.ai",
  uk: "uk-app.qure.ai",
  is: "india-stroke.qure.ai",
  beta2: "beta2-app.qure.ai"
};
var PERSISTED = {
  isFullWidthWorklist: "isFullWidthWorklist",
  filterObj: "filterObj",
  savedFilterData: "savedFilterData",
  isDarkMode: "isDarkMode",
  isSwiftReportModeEnabled: "isSwiftReportModeEnabled",
  notificationPromptDismiss: "notificationPromptDismiss",
  fcmToken: "fcmToken",
  redirectAfterLogin: "redirectAfterLogin"
};
var DEFAULT_FILTER_NAME = {
  negativeCXR: "Review Negative CXR"
};
var MPPS_STATUS = {
  DISCONTINUED: "DISCONTINUED",
  COMPLETED: "COMPLETED",
  IN_PROGRESS: "IN PROGRESS",
  REGISTERED: "REGISTERED"
};
var TERMS_OF_USE_VERSION = "2.0.0";
var UPLOAD_UI_STATE = {
  BROWSE_FILE: "BROWSE_FILE",
  CROP_FILE: "CROP_FILE",
  CROP_COMPLETE: "CROP_COMPLETE"
};
var UPLOAD_STATE = {
  NOT_STARTED: "NOT_STARTED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETE: "COMPLETE",
  FAILED: "FAILED",
  FAILED_LOW_RES: "FAILED_LOW_RES"
};
var GATEWAY_TIME = {
  TIME_DIFFERENCE: 16
};
var ERROR_TYPE = {
  ERROR: "ERROR",
  WARNING: "WARNING",
  VIEWER_FEEDBACK: "VIEWER_FEEDBACK"
};
export default {
  PAGE_SIZE: PAGE_SIZE,
  GENDERS: GENDERS,
  AI_INFERENCE: AI_INFERENCE,
  RN_COMM_CONST: RN_COMM_CONST,
  STATUS_OPTIONS: STATUS_OPTIONS,
  SCREENS: SCREENS,
  MEDIA_TYPES: MEDIA_TYPES,
  IMAGE_TYPES: IMAGE_TYPES,
  DOC_TYPES: DOC_TYPES,
  AUDIO_TYPES: AUDIO_TYPES,
  PLATFORMS: PLATFORMS,
  FORMS: FORMS,
  NOTIFICATION_EVENTS: NOTIFICATION_EVENTS,
  FORM_FIELDS: FORM_FIELDS,
  DEEFAULT_FILTER: DEEFAULT_FILTER,
  DICOM_METADATA_TAGS: DICOM_METADATA_TAGS,
  REQUIRED_DICOM_METADATA: REQUIRED_DICOM_METADATA,
  REGION_LABELS: REGION_LABELS,
  REGIONS: REGIONS,
  PERSISTED: PERSISTED,
  TERMS_OF_USE_VERSION: TERMS_OF_USE_VERSION,
  DEFAULT_FILTER_NAME: DEFAULT_FILTER_NAME,
  MPPS_STATUS: MPPS_STATUS,
  UPLOAD_UI_STATE: UPLOAD_UI_STATE,
  UPLOAD_STATE: UPLOAD_STATE,
  GATEWAY_TIME: GATEWAY_TIME,
  ERROR_TYPE: ERROR_TYPE
};