import { get } from "lodash";
import Pill from "../Characteristic/CharacteristicBuilder/Pill";

function Organs(props) {
  const { trialData, setTrialData } = props;
  const organs = get(trialData, ["classification", "organs"], []);
  return (
    <div className="p-5 flex gap-3 flex-wrap">
      {organs.map((organ) => {
        return <Pill key={organ} label={organ} />;
      })}
    </div>
  );
}

export default Organs;
