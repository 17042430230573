import { useState } from "react";
import { CLASSIFICATION_ACCORDION_MENU } from "../../../utils";
import classNames from "classnames";

function Classification({
  trialData,
  setTrialData
}) {
  const [activeAccordion, setActiveAccordion] = useState();

  return (
    <div className="grow h-[1px] flex flex-col overflow-y-scroll">
      {CLASSIFICATION_ACCORDION_MENU.map((menuItem) => {
        const { id, label, accordion: Accordion } = menuItem;
        const isOpen = id === activeAccordion
        return (
          <div key={id} className={classNames("flex flex-col border-b text-[12px] border-gray-700 transition-all", {
            "grow": isOpen
          })}>
            <button
              className="p-5 flex items-center justify-between"
              onClick={() => {
                activeAccordion === id ? setActiveAccordion() : setActiveAccordion(id);
              }}
            >
              <span className="uppercase font-semibold">{label}</span>
              <span className={classNames("transition-all duration-300", {
                "rotate-180": id === activeAccordion
              })}>                
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 10L12 14L16 10"
                    stroke="#BBBFC2"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
            </button>

            <div
              className={classNames("grow h-0 bg-gray-900 overflow-y-scroll transition-all", {
                "h-[200px]": isOpen
              })}
            >
              <Accordion
                trialData={trialData}
                setTrialData={setTrialData}
              />
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Classification;
