import { useStoreState } from "pullstate";
import Store from "../../utils/Store";
import Sidebar from "./Sidebar";
import Main from "./Main";
import { useState } from "react";
import { CloseSvg, TRIAL_MANAGEMENT } from "./utils";
import { get } from "lodash";

function RecistAdmin() {
  const { isVisible, userInfo } = useStoreState(Store, (s) => ({
    isVisible: s.isRecistAdminOpen,
    userInfo: s.userInfo
  }));
  const sourceId = get(userInfo, ["upload_sources", 0, "uuid"], "")

  const [activeMenu, setActiveMenu] = useState(TRIAL_MANAGEMENT);

  const onClose = () => {
    Store.update((s) => {
      s.isRecistAdminOpen = false;
    });

  };
  const updateActiveMenu = (selectedActiveMenu) => {
    setActiveMenu(selectedActiveMenu);
  };

  if (!isVisible) {
    return null;
  }

  return (
    <div className="z-[99] fixed top-0 left-0 w-screen h-screen flex items-center justify-center bg-gray-50 bg-opacity-20">
      <div className="relative w-[70%] h-[80%] text-gray-100 flex rounded-6 overflow-hidden">
        <button
          className="absolute top-2 right-2"
          onClick={() => {
            onClose();
          }}
        >
          <CloseSvg />
        </button>

        <Sidebar activeMenu={activeMenu} updateActiveMenu={updateActiveMenu} />

        <Main
          activeMenu={activeMenu}
          sourceId={sourceId}
        />
      </div>
    </div>
  );
}

export default RecistAdmin;
