import { cloneDeep, get } from "lodash";
import AddCharacteristic from "./CharacteristicBuilder/AddCharacteristic";
import CharacteristicTypes from "./CharacteristicBuilder/CharacteristicTypes";
import DeleteCharacteristic from "./CharacteristicBuilder/DeleteCharacteristic";
import Pill from "./CharacteristicBuilder/Pill";

function AccordionContent({ organ, characteristics, trialData, setTrialData }) {
  const characteristicTypes = get(trialData, ["characterstics", "options"], []);

  const onDeleteCharacteristicHandler = (organ, characteristicToDelete) => {
    const newTrialDataCopy = cloneDeep(trialData);
    delete newTrialDataCopy.characterstics.organs[organ][characteristicToDelete];
    setTrialData(newTrialDataCopy);
  };

  const addCharacteristicHandler = (organ, newCharacteristic) => {
    const updatedNewTrialData = {
      ...trialData,
      characterstics: {
        ...trialData.characterstics,
        organs: {
          ...trialData.characterstics.organs,
          [organ]: {
            ...trialData.characterstics.organs[organ],
            [newCharacteristic]: -1,
          },
        },
      },
    };

    setTrialData(updatedNewTrialData);
  };

  const onTypeChangeHandler = (organ, characteristic, updatedType) => {

    if (trialData.isSet) return
    
    const updatedNewTrialData = {
      ...trialData,
      characterstics: {
        ...trialData.characterstics,
        organs: {
          ...trialData.characterstics.organs,
          [organ]: {
            ...trialData.characterstics.organs[organ],
            [characteristic]: updatedType,
          },
        },
      },
    };

    setTrialData(updatedNewTrialData);
  };

  return (
    <div className="p-5 space-y-5">
      <div className="space-y-3">
        {Object.keys(characteristics).map((characteristic) => {
          const selectedType = characteristics[characteristic];
          return (
            <div key={characteristic} className="grid grid-cols-12">
              <div className="col-span-3 flex items-center">
                <Pill label={characteristic} />
              </div>

              <div className="col-span-8 flex items-center">
                <CharacteristicTypes
                  characteristicTypes={characteristicTypes}
                  organ={organ}
                  characteristic={characteristic}
                  selectedType={selectedType}
                  onTypeChangeHandler={onTypeChangeHandler}
                />
              </div>

              {!trialData.isSet && (
                <div className="col-span-1 flex items-center">
                  <DeleteCharacteristic
                    organ={organ}
                    characteristic={characteristic}
                    onDeleteCharacteristicHandler={onDeleteCharacteristicHandler}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>

      {!trialData.isSet && (
        <AddCharacteristic
          organ={organ}
          addCharacteristicHandler={addCharacteristicHandler}
        />
      )}
    </div>
  );
}

export default AccordionContent;
