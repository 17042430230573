import { useStoreState } from "pullstate";
import NotificationSettings from ".";
import Store from "../../utils/Store";
import Modal from "../common/Modal";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";
import Toast from "../Toast";

const NotificationSettingsModal = () => {
  const { t } = useTranslation();
  const { isVisible } = useStoreState(Store, (s) => ({
    isVisible: s.isNotificationSettingsOpen,
  }));

  const onCloseModal = () => {
    Store.update((s) => {
      s.isNotificationSettingsOpen = false;
    });
  };

  useEffect(() => {
    if (isVisible) {
      Toast.success("To Be Released in Future Updates")
    }
  }, [isVisible])

  return (
    <Modal
      title={t("setting_notification")}
      onClose={onCloseModal}
      show={isVisible}
      className="flex flex-col w-full sm:w-7/12 lg:w-6/12 xl:w-4/12 h-4/5 overflow-hidden"
    >
      <div className="relative h-full w-full blur-sm">
        {/* Disabled for PDS */}
        <div className="absolute top-0 left-0 h-full w-full z-[999] flex items-center justify-center">
        </div>
        <NotificationSettings isModal isVisible={isVisible} onClose={onCloseModal} />
      </div>
    </Modal>
  );
};

export default NotificationSettingsModal;
