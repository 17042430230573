function AddCharacteristic({ organ, addCharacteristicHandler }) {
  return (
    <div className="">
      <form className="flex space-x-5" onSubmit={(e) => {
          e.preventDefault()
          const formData = new FormData(e.target);
          const data = Object.fromEntries(formData.entries());
          addCharacteristicHandler(organ, data[organ + "characteristicLabel"])
          e.target.reset()
        }}>
        <div className="bg-gray-800 px-3 py-2 rounded-6 w-1/3">
          <div className="border-b border-teal-500">
            <input
              type="text"
              name={organ + "characteristicLabel"}
              className="text-[12px] text-center bg-transparent p-0 w-full border-none"
              required={true}
            />
          </div>
        </div>
        <button className="bg-teal-500 px-6 rounded-6">Add</button>
      </form>
    </div>
  );
}

export default AddCharacteristic;
