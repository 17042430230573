import MeasurableLesion from "./Components/AccordionMenus/Classification/MeasurableLesion";
import Organs from "./Components/AccordionMenus/Classification/Organs";

const TRIAL_MANAGEMENT = "trial_management";

const USER_MANAGEMENT = "user_management";

const EXPORT_DATA = "export_data";

const USER_SETTINGS_MENU = [
  {
    id: TRIAL_MANAGEMENT,
    label: "Trial Management",
    icon: () => {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21.7158 16.8847C22.0947 16.5093 22.0947 15.9023 21.7158 15.5309C21.3368 15.1596 20.724 15.1556 20.3491 15.5309L18.1318 17.7274L18.1278 4.06569C18.1278 3.53456 17.6964 3.10726 17.1602 3.10726C16.6241 3.10726 16.1927 3.53456 16.1927 4.06569L16.1927 17.7274L13.9754 15.5309C13.5965 15.1556 12.9837 15.1556 12.6087 15.5309C12.2338 15.9063 12.2298 16.5133 12.6087 16.8847L16.4749 20.7185C16.8539 21.0938 17.4666 21.0938 17.8416 20.7185L21.7117 16.8847L21.7158 16.8847ZM7.52509 3.3868C7.14614 3.01142 6.53336 3.01142 6.15844 3.3868L2.28422 7.22053C1.90526 7.59592 1.90526 8.20293 2.28422 8.57432C2.66317 8.94571 3.27595 8.94971 3.65088 8.57432L5.86817 6.37791L5.8722 20.0396C5.8722 20.5707 6.30357 20.998 6.83975 20.998C7.37593 20.998 7.8073 20.5707 7.8073 20.0396L7.8073 6.37791L10.0246 8.57432C10.4035 8.94971 11.0163 8.94971 11.3913 8.57432C11.7662 8.19893 11.7702 7.59193 11.3913 7.22053L7.52106 3.3868L7.52509 3.3868Z"
            fill="#BBBFC2"
          />
        </svg>
      );
    },
  },
  {
    id: USER_MANAGEMENT,
    label: "User Management",
    icon: () => {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8.75485 12C11.1451 12 13.0824 10.0826 13.0824 7.71753C13.0824 5.35247 11.1451 3.43506 8.75485 3.43506C6.36456 3.43506 4.4273 5.35247 4.4273 7.71753C4.4273 10.0826 6.36456 12 8.75485 12ZM8.75485 5.04099C10.2462 5.04099 11.4596 6.24175 11.4596 7.71753C11.4596 9.19331 10.2462 10.3941 8.75485 10.3941C7.26354 10.3941 6.05013 9.19297 6.05013 7.71753C6.05013 6.24175 7.26388 5.04099 8.75485 5.04099ZM10.469 13.6059H7.04074C3.8042 13.6059 1.18164 16.2022 1.18164 19.404C1.18164 20.0464 1.70636 20.5649 2.35346 20.5649H15.1549C15.804 20.5649 16.3281 20.0464 16.3281 19.404C16.3281 16.2022 13.7045 13.6059 10.469 13.6059ZM2.82848 18.959C3.05398 16.8546 4.85668 15.2119 7.04074 15.2119H10.469C12.6534 15.2119 14.428 16.8556 14.6816 18.959H2.82848ZM17.3795 14.1412H14.8827C16.4363 15.4026 17.41 17.2895 17.41 19.404C17.41 19.8322 17.2815 20.227 17.0719 20.5649H21.7375C22.3359 20.5649 22.8194 20.0832 22.8194 19.4642C22.8194 16.5367 20.3987 14.1412 17.3795 14.1412ZM15.7871 12C17.8799 12 19.5737 10.3238 19.5737 8.25284C19.5737 6.18186 17.8799 4.50568 15.7871 4.50568C14.9382 4.50568 14.1629 4.7919 13.5314 5.25879C13.9209 5.99885 14.1643 6.82758 14.1643 7.71753C14.1643 8.90591 13.7609 9.99728 13.0963 10.8862C13.7822 11.5718 14.7323 12 15.7871 12Z"
            fill="#BBBFC2"
          />
        </svg>
      );
    },
  },
];

const OTHERS_MENU = [
  {
    id: EXPORT_DATA,
    label: "Export Data",
    icon: () => {
      return (
        <svg
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3.5 14V2C3.5 1.725 3.725 1.5 4 1.5H9V4C9 4.55312 9.44688 5 10 5H12.5V14C12.5 14.275 12.275 14.5 12 14.5H4C3.725 14.5 3.5 14.275 3.5 14ZM4 0C2.89688 0 2 0.896875 2 2V14C2 15.1031 2.89688 16 4 16H12C13.1031 16 14 15.1031 14 14V4.82812C14 4.29688 13.7906 3.7875 13.4156 3.4125L10.5844 0.584375C10.2094 0.209375 9.70312 0 9.17188 0H4ZM7.5 8V9.5H5.5V8H7.5ZM5.5 10.5H7.5V12H5.5V10.5ZM8.5 10.5H10.5V12H8.5V10.5ZM8 13H8.5H10.5C11.0531 13 11.5 12.5531 11.5 12V10.5V10V9.5V8C11.5 7.44688 11.0531 7 10.5 7H8.5H8H7.5H5.5C4.94688 7 4.5 7.44688 4.5 8V9.5V10V10.5V12C4.5 12.5531 4.94688 13 5.5 13H7.5H8ZM8.5 9.5V8H10.5V9.5H8.5Z"
            fill="#BBBFC2"
          />
        </svg>
      );
    },
  },
];

const CLASSIFICATION = "classification";

const CHARACTERISTIC = "characteristic";

const CLASSIFICATION_CHARACTERISTIC_MENU = [
  {
    id: CLASSIFICATION,
    label: "Classification",
  },
  {
    id: CHARACTERISTIC,
    label: "Characteristic",
  },
];

const MEASURABLE_LESIONS_ACCORDION = "measurable_lesions";
const NON_MEASURABLE_LESIONS_ACCORDION = "non_measurable_lesions";
const ORGANS_ACCORDION = "organs";
const CLASSIFICATION_ACCORDION_MENU = [
  {
    id: MEASURABLE_LESIONS_ACCORDION,
    label: "Measurable Lesions",
    accordion: (props) => {
      return <MeasurableLesion {...props} />;
    },
  },
  //{
  //  id: NON_MEASURABLE_LESIONS_ACCORDION,
  //  label: "Non Measurable Lesions",
  //  accordion: () => {
  //    return <div>{NON_MEASURABLE_LESIONS_ACCORDION}</div>;
  //  },
  //},
  {
    id: ORGANS_ACCORDION,
    label: "Organs",
    accordion: (props) => {
      return <Organs {...props} />
    },
  },
];

const CHARACTERISTIC_OPTIONS = [
  {
    id: "lung",
    label: "Lung",
  },
  {
    id: "lymph_nodes",
    label: "Lymph Nodes",
  },
  {
    id: "renal",
    label: "Renal",
  },
  {
    id: "soft_tissue",
    label: "Soft Tissue",
  },
]

const CHARACTERISTIC_TYPES = [
  {
    id: "boolean",
    label: "Present/Absent"
  },
  {
    id: "number",
    label: "Numeric Value"
  },
  {
    id: "text",
    label: "Free Text"
  },
]

const CheckboxSVG = ({ isChecked }) => {
  if (isChecked) {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3 1C1.89688 1 1 1.89688 1 3V13C1 14.1031 1.89688 15 3 15H13C14.1031 15 15 14.1031 15 13V3C15 1.89688 14.1031 1 13 1H3ZM11.5313 6.53125L7.53125 10.5312C7.2375 10.825 6.7625 10.825 6.47188 10.5312L4.47188 8.53125C4.17813 8.2375 4.17813 7.7625 4.47188 7.47188C4.76563 7.18125 5.24063 7.17813 5.53125 7.47188L7 8.94063L10.4688 5.46875C10.7625 5.175 11.2375 5.175 11.5281 5.46875C11.8188 5.7625 11.8219 6.2375 11.5281 6.52813L11.5313 6.53125Z"
          fill="#00A9A7"
        />
        <path
          d="M11.5313 6.53125L7.53125 10.5312C7.2375 10.825 6.7625 10.825 6.47188 10.5312L4.47188 8.53125C4.17813 8.2375 4.17813 7.7625 4.47188 7.47188C4.76563 7.18125 5.24063 7.17813 5.53125 7.47188L7 8.94063L10.4688 5.46875C10.7625 5.175 11.2375 5.175 11.5281 5.46875C11.8188 5.7625 11.8219 6.2375 11.5281 6.52813L11.5313 6.53125Z"
          fill="#0C1723"
        />
      </svg>
    );
  } else {
    return (
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.53125 3.5C1.53125 2.39531 2.42656 1.5 3.53125 1.5H13.5313C14.6344 1.5 15.5312 2.39531 15.5312 3.5V13.5C15.5312 14.6031 14.6344 15.5 13.5313 15.5H3.53125C2.42656 15.5 1.53125 14.6031 1.53125 13.5V3.5ZM3.03125 3.5V13.5C3.03125 13.775 3.255 14 3.53125 14H13.5313C13.8063 14 14.0313 13.775 14.0313 13.5V3.5C14.0313 3.22375 13.8063 3 13.5313 3H3.53125C3.255 3 3.03125 3.22375 3.03125 3.5Z"
          fill="#9EA3A8"
        />
      </svg>
    );
  }
};

const CloseSvg = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="32" height="32" rx="8" fill="#474F58" />
      <path
        d="M23.6066 21.957C24.0751 22.4242 24.0751 23.1823 23.6066 23.6496C23.1381 24.1168 22.378 24.1168 21.9094 23.6496L16.0008 17.713L10.0502 23.6476C9.5817 24.1148 8.82157 24.1148 8.35302 23.6476C7.88448 23.1803 7.88453 22.4223 8.35302 21.955L14.3056 16.0224L8.35137 10.043C7.88288 9.5758 7.88288 8.81772 8.35137 8.35044C8.81987 7.88316 9.58 7.88321 10.0485 8.35044L16.0008 14.3318L21.9514 8.39732C22.4199 7.93009 23.1801 7.93009 23.6486 8.39732C24.1172 8.86455 24.1171 9.62263 23.6486 10.0899L17.696 16.0224L23.6066 21.957Z"
        fill="#F6F7F7"
      />
    </svg>
  );
};

const RECIST_ADMIN_OPTIONS_URL = "recist/admin/options/";
const SAVE_TRIAL_URL = "recist/admin/save/";

export {
  TRIAL_MANAGEMENT,
  USER_MANAGEMENT,
  USER_SETTINGS_MENU,
  OTHERS_MENU,
  EXPORT_DATA,
  CLASSIFICATION,
  CHARACTERISTIC,
  CLASSIFICATION_CHARACTERISTIC_MENU,
  MEASURABLE_LESIONS_ACCORDION,
  NON_MEASURABLE_LESIONS_ACCORDION,
  ORGANS_ACCORDION,
  CLASSIFICATION_ACCORDION_MENU,
  CHARACTERISTIC_OPTIONS,
  CHARACTERISTIC_TYPES,
  CheckboxSVG,
  CloseSvg,
  RECIST_ADMIN_OPTIONS_URL,
  SAVE_TRIAL_URL
};
