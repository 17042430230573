import {
  TRIAL_MANAGEMENT,
  USER_MANAGEMENT,
  EXPORT_DATA,
  RECIST_ADMIN_OPTIONS_URL,
  SAVE_TRIAL_URL,
} from "./utils";
import TrialManagement from "./Menus/TrialManagement";
import UserManagement from "./Menus/UserManagement";
import ExportData from "./Menus/ExportData";
import Toast from "../Toast";
import servicesUtils from "../../utils/servicesUtils"
import { useEffect, useState } from "react";

function Main({ activeMenu, sourceId }) {

  const [trialData, setTrialData] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getTrialManagementData = async () => {
    try {
      const payload = {
        source_id: sourceId,
      };
      const response = await servicesUtils.postService(RECIST_ADMIN_OPTIONS_URL, payload);
      const data = response.config;
      const isSet = data.set;
      if (isSet) {
        setTrialData({
          template_name: data.template_name,
          isSet: true,
          ...data.cfg,
        });
      } else {
        setTrialData({
          template_name: data.template_name,
          isSet: false,
          ...data.def_cfg,
        });
      }
      setIsLoading(false)
    } catch (error) {
      console.log(error);
    }
  };


  const saveTrial = async () => {
    try {
      const payload = {
        source_id: sourceId,
        template_name: trialData["template_name"],
        cfg: {
          classification: trialData["classification"],
          characterstics: trialData["characterstics"],
        },
      };

      await servicesUtils.postService(SAVE_TRIAL_URL, payload);
      getTrialManagementData()
      Toast.success("Trial successfully saved")
    } catch (error) {
      Toast.error("Error while saving trial")
      console.error(error)
    }
  };


  useEffect(() => {
    getTrialManagementData();
  }, []);

  return (
    <div className="w-2/3 bg-gray-800 flex flex-col">
      {activeMenu === TRIAL_MANAGEMENT && (
        <TrialManagement
          trialData={trialData}
          setTrialData={setTrialData}
          saveTrial={saveTrial}
          isLoading={isLoading}
        />
      )}
      {activeMenu === USER_MANAGEMENT && <UserManagement />}
      {activeMenu === EXPORT_DATA && <ExportData />}
    </div>
  );
}

export default Main;
