import { get } from "lodash";
import { CheckboxSVG } from "../../../utils";

function MeasurableLesion(props) {
  const { trialData, setTrialData } = props;

  const totalTargetPerPatient = get(
    trialData,
    ["classification", "measurable_lesions", "target", "patient", "max"],
    5
  );
  const totalTargetPerOrgan = get(
    trialData,
    ["classification", "measurable_lesions", "target", "organ", "max"],
    2
  );

  const lesionDiameter = get(
    trialData,
    ["classification", "measurable_lesions", "diameter", "lesion", "long-axis"],
    5
  );
  const lymphNodeDiameter = get(
    trialData,
    ["classification", "measurable_lesions", "diameter", "lymph_node", "short-axis"],
    5
  );

  const onTotalTargetsChangeHandler = (isPatientOrOrgan, updatedValue) => {
    
    if (trialData.isSet) return

    const updatedNewTrialData = {
      ...trialData,
      classification: {
        ...trialData.classification,
        measurable_lesions: {
          ...trialData.classification.measurable_lesions,
          target: {
            ...trialData.classification.measurable_lesions.target,
            [isPatientOrOrgan]: {
              max: parseInt(updatedValue),
              min: 0,
            },
          },
        },
      },
    };

    setTrialData(updatedNewTrialData);
  };

  const diameterChangeHandler = (isLesionOrLymphNode, isShortOrLong, updatedValue) => {

    if (trialData.isSet) return

    const updatedNewTrialData = {
      ...trialData,
      classification: {
        ...trialData.classification,
        measurable_lesions: {
          ...trialData.classification.measurable_lesions,
          diameter: {
            ...trialData.classification.measurable_lesions.diameter,
            [isLesionOrLymphNode]: {
              [isShortOrLong]: parseInt(updatedValue),
            },
          },
        },
      },
    };

    setTrialData(updatedNewTrialData);
  };

  return (
    <div className="p-5 space-y-7">
      <div className="space-y-5">
        <div className="uppercase font-semibold">lesion type</div>

        <div className="flex items-center space-x-5 px-5">
          <div className="space-y-3">
            <div className="font-medium">Total targets</div>
            <div className="rounded-6 border border-gray-700 w-[100px]">
              <input
                type="number"
                className="text-[12px] text-center bg-transparent p-0 py-2 w-full border-none"
                value={totalTargetPerPatient}
                onChange={(e) => {
                  onTotalTargetsChangeHandler("patient", e.target.value);
                }}
              />
            </div>
          </div>

          <div className="space-y-3">
            <div className="font-medium">Targets/Organs</div>
            <div className="rounded-6 border border-gray-700 w-[100px]">
              <input
                type="number"
                className="text-[12px] text-center bg-transparent p-0 py-2 w-full border-none"
                value={totalTargetPerOrgan}
                onChange={(e) => {
                  onTotalTargetsChangeHandler("organ", e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-5">
        <div className="uppercase font-semibold">min diameter for targets</div>

        <div className="flex items-center space-x-5 px-5">
          <div className="space-y-3">
            <div className="font-medium">Lesion</div>
            <div className="flex items-center space-x-3">
              <div className="rounded-6 border border-gray-700 w-[100px]">
                <input
                  type="number"
                  className="text-[12px] text-center bg-transparent p-0 py-2 w-full border-none"
                  value={lesionDiameter}
                  onChange={(e) => {
                    diameterChangeHandler("lesion", "long-axis", e.target.value);
                  }}
                />
              </div>
              <span>MM</span>
            </div>
          </div>
          <div className="space-y-3">
            <div className="font-medium">Lymph Node</div>
            <div className="flex items-center space-x-3">
              <div className="rounded-6 border border-gray-700 w-[100px]">
                <input
                  type="number"
                  className="text-[12px] text-center bg-transparent p-0 py-2 w-full border-none"
                  value={lymphNodeDiameter}
                  onChange={(e) => {
                    diameterChangeHandler("lymph_node", "short-axis", e.target.value);
                  }}
                />
              </div>
              <span>MM</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MeasurableLesion;
