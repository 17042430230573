import { get } from "lodash";
import { useState } from "react";
import {
  CHARACTERISTIC,
  CLASSIFICATION,
  CLASSIFICATION_CHARACTERISTIC_MENU,
} from "../utils";
import classNames from "classnames";
import Classification from "./AccordionMenus/Classification/Index";
import Characteristic from "./AccordionMenus/Characteristic/Index";

function NewTrial({ trialData, setTrialData, saveTrial }) {
  const criteria = get(trialData, ["template_name"], "");

  const [activeMenu, setActiveMenu] = useState(CLASSIFICATION);

  const criteriaNameChangeHandler = (e) => {

    if (trialData.isSet) return

    const updatedNewTrialData = {
      ...trialData,
      template_name: e.target.value,
    };
    setTrialData(updatedNewTrialData);
  };

  const validateTrialData = () => {
    if (!criteria) {
      return true;
    }

    const organs = get(trialData, ["characterstics", "organs"]);
    for (let organ in organs) {
      const characteristics = organs[organ];
      for (let characteristic in characteristics) {
        const type = characteristics[characteristic];
        if (type === -1) {
          return true;
        }
      }
    }
    return false;
  };
  const isSaveDisabled = validateTrialData();

  return (
    <div className="grow flex flex-col px-5 py-5 space-y-5">
      <div className="flex items-center space-x-5">
        <div>Criteria</div>

        <div className="bg-gray-700 p-2 px-5 rounded-6 w-full">
          <input
            type="text"
            className="w-full text-[14px] bg-transparent border-none p-0"
            value={criteria}
            onChange={criteriaNameChangeHandler}
          />
        </div>
      </div>

      <div className="grow flex flex-col rounded-6 border border-gray-700 overflow-hidden">
        <div className="border-b border-gray-700 px-5 space-x-5">
          {CLASSIFICATION_CHARACTERISTIC_MENU.map((menuItem) => {
            const { id, label } = menuItem;
            return (
              <button
                key={id}
                className={classNames(
                  "uppercase text-gray-500 text-[12px] font-semibold py-5",
                  {
                    "!text-gray-100 border-b-2 border-teal-400": id === activeMenu,
                  }
                )}
                onClick={() => {
                  setActiveMenu(id);
                }}
              >
                {label}
              </button>
            );
          })}
        </div>

        {activeMenu === CLASSIFICATION && (
          <Classification trialData={trialData} setTrialData={setTrialData} />
        )}
        {activeMenu === CHARACTERISTIC && (
          <Characteristic trialData={trialData} setTrialData={setTrialData} />
        )}
      </div>

      {!trialData.isSet && (
        <div className="px-5 flex items-center justify-end space-x-5">
          {/*<button className="bg-gray-600 w-24 rounded-6 py-3">Cancel</button>*/}
          <button
            disabled={isSaveDisabled}
            className={classNames("bg-teal-500 w-24 rounded-6 py-3", {
              "bg-teal-900": isSaveDisabled,
            })}
            onClick={saveTrial}
          >
            Save
          </button>
        </div>
      )}
    </div>
  );
}

export default NewTrial;
