import { createContext, useContext, useEffect, useState } from "react";
import servicesUtils from "../../utils/servicesUtils";
import { PatientsContext } from "../Patients/PatientsContext";
import useWebsocket from "../../hooks/useWebsocket";
import { useStoreState } from "pullstate";
import Store from "../../utils/Store";
import { get, isEmpty } from "lodash";
import Toast from "../Toast";

const BOTTOM_PANEL_URL = "seg/";
const lesionDeleteWsAction = "Successful Lesion Deletion";
const segCreationWsAction = "Successful SEG creation";

export const RecistConfigContext = createContext();

function RecistConfigContextProvider({ children }) {
  // getting current patient
  const { currentPatient, setCurrentPatient } = useContext(PatientsContext);
  const patient_id = get(currentPatient, ["patient_id"]);
  const source_id = get(currentPatient, ["source_id"]);

  const { user } = useStoreState(Store, (s) => ({
    user: s.user,
  }));

  const userId = get(user, ["sub"], "");

  const { socket: segSocket, wsData: segWsData } = useWebsocket(
    `/seg/${userId}/`,
    !isEmpty(user)
  );
  const { socket: failureSocket, wsData: failureWsData } = useWebsocket(
    `/failures/${userId}/`,
    !isEmpty(user)
  );

  const [data, setData] = useState();
  const [recistConfig, setRecistConfig] = useState();
  const [bottomPanelError, setBottomPanelError] = useState();
  const [showLesionTypeMenu, setShowLesionTypeMenu] = useState();
  const [seriesInfo, setSeriesInfo] = useState();
  const [seriesInstanceUid, setSeriesInstanceUid] = useState();

  async function getBottomPanelData() {
    try {
      //setData();
      Store.update((s) => {
        s.lesionToLink = {};
        s.blockedLesion = {};
        s.selectedLesion = {};
      });
      setShowLesionTypeMenu();
      if (isEmpty(currentPatient)) return;
      const response = await servicesUtils.postService(BOTTOM_PANEL_URL, {
        patient_id: patient_id,
      });
      if (!response.status) throw new Error("Error while fetching data");

      setSeriesInfo(response.response.series_info);
      setBottomPanelError();
      setData(response.response);
      response.response.lesions.sort((a, b) => a.classification_id - b.classification_id);
      return response.response;
    } catch (error) {
      setBottomPanelError("Error while fetching data");
      Toast.error("Error while fetching data");
      console.log(error);
      return null;
    }
  }

  useEffect(() => {
    if (failureWsData) {
      try {
        const data = failureWsData.data;

        if (data.patient_id != patient_id) return;

        Toast.error("Something went wrong!");
        getBottomPanelData(BOTTOM_PANEL_URL)
          .then((responseData) => {
            setData(responseData);
          })
          .catch((e) => {
            console.error(e);
          });
      } catch (error) {
        console.error(error);
      }
    }
  }, [failureWsData]);

  useEffect(() => {
    try {
      if (segWsData) {
        const data = segWsData.data;

        if (data.patient_id != patient_id) return;

        getBottomPanelData(BOTTOM_PANEL_URL)
          .then((responseData) => {
            setData(responseData);
          })
          .catch((e) => {});

        if (data.action === lesionDeleteWsAction) {
          Toast.success("Lesion deleted successfully!");
        }
        if (data.action === segCreationWsAction) {
          Toast.success("Segmentation created successfully!");
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [segWsData]);

  useEffect(() => {
    if (isEmpty(currentPatient)) return;

    const payload = {
      source_id,
    };
    servicesUtils.postService("recist/options/", payload).then((response) => {
      if (response.status) {
        setRecistConfig(response.response);
      }
    });
  }, [currentPatient]);

  return (
    <RecistConfigContext.Provider
      value={{
        recistConfig,
        currentPatient,
        setCurrentPatient,
        segWsData,
        failureWsData,
        getBottomPanelData,
        data,
        setData,
        bottomPanelError,
        setBottomPanelError,
        showLesionTypeMenu,
        setShowLesionTypeMenu,
        seriesInfo,
        setSeriesInfo,
        seriesInstanceUid,
        setSeriesInstanceUid,
      }}
    >
      {children}
    </RecistConfigContext.Provider>
  );
}

export default RecistConfigContextProvider;
